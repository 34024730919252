import { useCallback } from 'react';
import { POOL_COLOR_OPTIONS } from '@lawnstarter/customer-modules/constants';
import { useOrderContext } from '@lawnstarter/customer-modules/contexts';
import { OrderPoolFlowKeys, PoolColors } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { SelectOption, SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';

import { PoolCleaningQuestion } from '@src/components';

export function PoolCurrentColorScreen() {
  const { updateOrder, goToNextStep, goToPreviousStep, currentState } = useOrderContext();
  const selectedValue = currentState?.[OrderPoolFlowKeys.CurrentPoolColor];

  const onSelect = useCallback(
    (key: SelectOptionItemKey) => {
      updateOrder(OrderPoolFlowKeys.CurrentPoolColor, key);

      if (key !== PoolColors.Clear) {
        goToNextStep();
      }
    },
    [updateOrder, goToNextStep],
  );

  return (
    <PoolCleaningQuestion
      title={t('services.poolCleaning.orderService.poolColor.title')}
      description={t('services.poolCleaning.orderService.poolColor.description')}
      progress={2 / 4}
      onBackButtonPress={goToPreviousStep}
    >
      <SelectOption onSelect={onSelect} options={POOL_COLOR_OPTIONS} selected={selectedValue} />
    </PoolCleaningQuestion>
  );
}
