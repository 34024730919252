import tw from 'tailwind-styled-components';

export const Container = tw.div`
  p-4
  sm:py-0
`;

export const BackButtonWrapper = tw.div`
  hidden mb-3
  sm:flex
`;

export const FormWrapper = tw.div`
  flex flex-col items-start gap-x-4
  sm:flex-row sm:items-start
  md:gap-x-20
`;

export const FormTitle = tw.div`
  text-2xl font-semibold mt-2
  sm:text-3xl sm:font-medium
`;

export const FormDescription = tw.div`
  text-sm mt-2
  sm:text-lg sm:mt-4
`;

export const SelectOptionWrapper = tw.div`
  flex-1 w-full mt-6
  sm:max-w-[200px] sm:mt-0
  md:max-w-[300px]
  lg:max-w-[400px]
`;
