import { OrderContextProvider, useOrderContext } from '@lawnstarter/customer-modules/contexts';
import { OrderPoolFlowKeys } from '@lawnstarter/customer-modules/enums';

import { PoolAboveGroundScreen } from '../PoolAboveGroundScreen';
import { PoolCurrentColorScreen } from '../PoolCurrentColorScreen';
import { PoolVisibleIssuesScreen } from '../PoolVisibleIssuesScreen';

const PoolCleaningQuestions = () => {
  const { currentStep } = useOrderContext();

  return currentStep.screen;
};

export const PoolCleaningQuestionsFlow = () => {
  return (
    <OrderContextProvider
      steps={{
        [OrderPoolFlowKeys.IsPoolAboveGround]: <PoolAboveGroundScreen />,
        [OrderPoolFlowKeys.CurrentPoolColor]: <PoolCurrentColorScreen />,
        [OrderPoolFlowKeys.PoolVisibleIssues]: <PoolVisibleIssuesScreen />,
      }}
    >
      <PoolCleaningQuestions />
    </OrderContextProvider>
  );
};
