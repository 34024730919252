import { useMemo } from 'react';
import { View } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertyIdSelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation, useSelector } from '@src/hooks';

import { styles } from './styles';

export function OrderPoolCleaningFooter() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const { navigate } = useRouteNavigation();
  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const onPress = () => {
    navigate(WebRoutes.orderPoolCleaningFlow, { params: { propertyId } });
  };

  return (
    <View style={style.footerContainer}>
      <View />
      <View>
        <Text allowFontScaling={false} style={style.footerText}>
          {t('services.poolCleaning.yearLong.poolPerfection')}
        </Text>
      </View>
      <Button
        onPress={onPress}
        trackID="order_pool_cleaning_footer-cta"
        buttonColor={theme.colors.primary}
        textColor={theme.colors.onPrimary}
        style={style.footerButton}
      >
        {t('services.poolCleaning.yearLong.letsCustomizeYourPlan')}
      </Button>
    </View>
  );
}
