import { useFlags } from 'flagsmith/react';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Divider } from 'react-native-paper';
import { useLocation } from 'react-router-dom';
import { useOrderPoolCleaningController } from '@lawnstarter/customer-modules/controllers';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, RichText, Text } from '@lawnstarter/ls-react-common/atoms';
import {
  Notice as NoticeType,
  RadioOptionVariant,
  ServiceType,
} from '@lawnstarter/ls-react-common/enums';
import { currency, frequency } from '@lawnstarter/ls-react-common/filters';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';
import { EntityList, ModalTemplate, Notice } from '@lawnstarter/ls-react-common/organisms';

import { Loader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';
import { errorService } from '@src/services';

import { StyledServiceWrapper, styles } from './styles';
import { YearLongPoolCleaningScreen } from './YearLongPoolCleaningScreen';

export function OrderPoolCleaningScreen() {
  const apiTracking = useApiTracking()!;
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const location = useLocation();
  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;
  useOrderServiceViewedTracking(apiTracking, ServiceType.PoolCleaning, errorService, source);

  const poolCleaningMinimumExperienceM1Flag = useFlags([
    FeatureFlags.POOL_CLEANING_MINIMUM_EXPERIENCE_M1,
  ])[FeatureFlags.POOL_CLEANING_MINIMUM_EXPERIENCE_M1];

  return poolCleaningMinimumExperienceM1Flag.enabled
    ? YearLongPoolCleaningScreen()
    : AboutPoolCleaningScreen();
}

function AboutPoolCleaningScreen() {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const style = useMemo(() => styles(theme), [theme]);
  const actionModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { startDates, poolCleaningBenefits, control, service, submit, isOrdering } =
    useOrderPoolCleaningController({
      actionConfirmationModal: actionModal,
      onSuccess: (schedule) =>
        navigate(WebRoutes.serviceDetail, {
          params: { propertyId: schedule.property_id, scheduleId: schedule.id },
          options: { replace: true },
        }),
    });

  useEffect(() => {
    if (!service || !service.pricingInformation) {
      navigate(WebRoutes.browseRedirect);
    }
  }, [service, navigate]);

  // Do not try to render the screen if the service is not found
  if (!service || !service.pricingInformation) {
    return <Loader />;
  }

  return (
    <StyledServiceWrapper>
      <div className="flex flex-col h-full">
        <Text style={style.title} variant="headlineSmall">
          {t('services.poolCleaning.enjoyPristinePool')}
        </Text>
        <Text>{t('services.poolCleaning.weeklyMaintenance')}</Text>

        <EntityList
          entities={poolCleaningBenefits}
          itemExtractor={({ id, label }) => ({
            key: id,
            label: (
              <div className="flex flex-row py-5 justify-between items-center">
                <div className="flex-1 flex-wrap">
                  <RichText>{label}</RichText>
                </div>
                <Icon name="check" size={25} color={theme.colors.primary} />
              </div>
            ),
          })}
        />
      </div>

      <div className="flex flex-col gap-2 h-full">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <Text style={style.title} variant="headlineSmall">
              {t('services.poolCleaning.name')}
            </Text>
            <Text style={style.title} variant="headlineSmall">
              {currency({ amount: service.pricingInformation.price })}
            </Text>
          </div>

          <div className="flex flex-row justify-between">
            <Text style={style.title}>
              {t('serviceFrequency', {
                frequency: frequency({ cycle: service.pricingInformation.cycle }),
              })}
            </Text>
            <Text style={style.title}>{t('perService')}</Text>
          </div>
        </div>

        <Divider />

        <form className="flex flex-col gap-4">
          <RadioOptionsForm
            noPadding
            name="starts_at"
            control={control}
            options={startDates}
            rules={{ required: true }}
            variant={RadioOptionVariant.Flat}
            trackID="pool_cleaning_screen-preferred_start_date"
            label={t('services.poolCleaning.preferredStartDate')}
          />

          <Notice type={NoticeType.Hint}>
            <div className="flex flex-col gap-4 pb-4">
              <Text>{t('services.poolCleaning.workingToFinalizeServiceDate')}</Text>
              <RichText>{t('services.poolCleaning.notChargedUntilServiceCompletion')}</RichText>
            </div>
          </Notice>

          <Button
            mode="contained"
            onPress={submit}
            loading={isOrdering}
            disabled={isOrdering}
            trackID="pool_cleaning_screen-book_pool_cleaning"
          >
            {t('services.poolCleaning.bookService')}
          </Button>
        </form>
      </div>
    </StyledServiceWrapper>
  );
}
