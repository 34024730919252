import { useMemo } from 'react';
import { View } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertyIdSelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation, useSelector } from '@src/hooks';
import { useWindowSize } from '@src/hooks/useWindowSize';

import { StyledAboutContainer, stylesLegacy } from './styles';

export function AboutPoolCleaning({ isLoading = false }: { isLoading?: boolean }) {
  const theme = useAppTheme();
  const style = useMemo(() => stylesLegacy(theme), [theme]);
  const { width } = useWindowSize();
  const { navigate } = useRouteNavigation();

  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const onPress = () => {
    navigate(WebRoutes.orderPoolCleaningFlow, { params: { propertyId } });
  };

  function renderAbout() {
    return (
      <View style={style.aboutContainer}>
        <Text variant="titleLarge" style={[style.boldFont, { fontSize: 28, textAlign: 'center' }]}>
          {t('services.poolCleaning.yearLong.poolPerfection')}
        </Text>
        <Button
          onPress={onPress}
          loading={isLoading}
          disabled={isLoading}
          trackID="about_lawn_treatment-submit_order"
          mode="contained"
          style={style.submitButton}
        >
          {width <= 768 ? t('continue') : t('services.poolCleaning.yearLong.letsCustomizeYourPlan')}
        </Button>
      </View>
    );
  }

  return <StyledAboutContainer>{renderAbout()}</StyledAboutContainer>;
}
