import { useMemo } from 'react';
import { Pressable } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation } from '@src/hooks';

import { styles } from './styles';

export function BackButton({ onPress }: { onPress?: () => void } = {}) {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const style = useMemo(() => styles(theme), [theme]);

  return (
    <Pressable
      accessibilityLabel={t('goBack')}
      onPress={onPress || (() => navigate(WebRoutes.back))}
    >
      <Icon name="arrow-left" size={theme.sizing.s6} style={style.icon} />
    </Pressable>
  );
}
