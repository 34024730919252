import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    orderPoolCleaningContainer: {
      backgroundColor: theme.colors.white.primary,
      padding: theme.sizing.s4,
      width: '100%',
    },
    orderPoolCleaningSubtitle: {
      fontWeight: '700',
    },
    orderPoolCleaningDescription: {
      marginTop: theme.sizing.s3,
    },
    orderPoolCleaningItems: {
      marginTop: theme.sizing.s3,
      flexDirection: 'row',
    },
    imagesContainer: {
      marginTop: theme.sizing.s5,
      flexDirection: 'column',
    },
    smallImagesContainer: {
      flex: 1,
      alignItems: 'center',
      marginVertical: theme.sizing.s3,
    },
    smallImage: {
      height: 240,
      width: '100%',
    },
    smallImageSubtitle: {
      position: 'absolute',
      top: '110%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },
    footer: {
      marginTop: theme.sizing.s2,
      fontWeight: '500',
      color: theme.colors.gray.tertiary,
      textAlign: 'center',
      padding: theme.sizing.s6,
    },
    contentContainer: {
      flex: 1,
      padding: 0,
    },
    greatPoolTransformations: {
      fontWeight: '700',
      marginTop: theme.sizing.s6,
    },
  });
};

export const StyledOrderPoolCleaningContainer = tw.div`
  flex flex-col items-center w-full max-w-screen-lg mx-auto
`;

export const StyledOrderPoolCleaningInfoContainer = tw.section`
  flex flex-col items-center w-full max-w-screen-lg mx-auto
`;

export const MobileOnlyWrapper = tw.div`
  flex flex-col md:hidden
`;

export const DesktopOnlyWrapper = tw.div`
  hidden md:flex flex-col w-full
`;
