import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const stylesLegacy = (theme: AppTheme) => {
  return StyleSheet.create({
    boldFont: {
      ...setFontWeight('600'),
      lineHeight: theme.sizing.s10,
    },
    title: {
      color: theme.colors.primary,
      marginLeft: theme.spacing.s2,
    },
    image: {
      height: 120,
      width: 192,
    },
    list: {
      marginLeft: theme.spacing.s4,
    },

    aboutContainer: {
      alignItems: 'center',
      lineHeight: theme.sizing.s3 - 1,
      borderWidth: 1,
      borderRadius: theme.sizing.s2,
      borderColor: theme.colors.gray.secondary,
      paddingTop: theme.sizing.s7,
      paddingRight: theme.sizing.s5,
      paddingBottom: theme.sizing.s5,
      paddingLeft: theme.sizing.s5,
      backgroundColor: theme.colors.white.primary,
      width: '100%',
    },
    submitButton: {
      width: '100%',
      maxWidth: 290,
      marginTop: theme.sizing.s10,
    },
  });
};

export const StyledAboutContainer = tw.div`
flex flex-col items-center justify-center gap-10 w-full mx-auto mt-5 sticky
`;
