import { useMemo } from 'react';
import { Image, ImageSourcePropType, SafeAreaView, View } from 'react-native';
import Slider from 'react-slick';
import PoolCleaningPhoto2 from '@lawnstarter/customer-modules/assets/services/pool_cleaning2.png';
import PoolCleaningPhoto1 from '@lawnstarter/customer-modules/assets/services/pool_image10.png';
import PoolCleaningPhoto3 from '@lawnstarter/customer-modules/assets/services/pool_image12.png';
import PoolCleaningPhoto4 from '@lawnstarter/customer-modules/assets/services/pool_image15.png';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';

import { OrderPoolCleaningFooter } from '@src/components/molecules/AboutPoolCleaning/footer/OrderPoolCleaningFooter';
import { StyledCarouselContainer } from '@src/components/organisms/RecommendationCarousel/styles.ts';
import { useWindowSize } from '@src/hooks/useWindowSize';

import { StyledOrderPoolCleaningInfoContainer, styles } from './MobileStyles';

export function MobileOrderPoolCleaningTemplate() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { width } = useWindowSize();

  const getCarouselItems = () => {
    return [PoolCleaningPhoto1, PoolCleaningPhoto2, PoolCleaningPhoto3, PoolCleaningPhoto4].flatMap(
      (poolPhoto) => ({
        title: '',
        imageUrl: poolPhoto,
        text: '',
        actionText: '',
        action: { routeName: '' },
      }),
    );
  };

  const renderItems = () => {
    return getCarouselItems().map((item, index) => (
      <View key={index} style={style.imagesContainer}>
        <View style={style.smallImagesContainer}>
          <Image source={item.imageUrl as ImageSourcePropType} style={style.smallImage} />
          <Text variant="labelMedium" style={style.smallImageSubtitle}>
            {item.text}
          </Text>
        </View>
      </View>
    ));
  };

  const renderOrderPoolCleaningContainer = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
    };

    return (
      <StyledCarouselContainer>
        <Slider {...settings}>{renderItems()}</Slider>
      </StyledCarouselContainer>
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        // Necessary to accommodate our new footer.
        marginBottom: 30,
        zIndex: 20,
      }}
    >
      {width < 768 ? (
        <ScrollView contentContainerStyleOverride={style.contentContainer}>
          <StyledOrderPoolCleaningInfoContainer>
            <View style={style.orderPoolCleaningContainer}>
              <Text variant="labelLarge" style={style.orderPoolCleaningSubtitle}>
                {t('services.poolCleaning.yearLong.header')}
              </Text>

              <Text variant="labelLarge" style={style.orderPoolCleaningDescription}>
                {t('services.poolCleaning.yearLong.description')}
              </Text>

              <View style={style.orderPoolCleaningItems}>
                <Icon name="cards-diamond-outline" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('services.poolCleaning.yearLong.crystalClearPoolFloor')}
                </Text>
              </View>

              <View style={style.orderPoolCleaningItems}>
                <Icon name="broom" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('services.poolCleaning.yearLong.algaeFreeTilesAndWalls')}
                </Text>
              </View>

              <View style={style.orderPoolCleaningItems}>
                <Icon name="swim" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('services.poolCleaning.yearLong.debrisFreeSurface')}
                </Text>
              </View>

              <View style={style.orderPoolCleaningItems}>
                <Icon name="waves" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('services.poolCleaning.yearLong.perfectWaterBalance')}
                </Text>
              </View>

              <View style={style.orderPoolCleaningItems}>
                <Icon name="filter-outline" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('services.poolCleaning.yearLong.efficientFiltration')}
                </Text>
              </View>

              <View>
                <Text variant="labelLarge" style={style.greatPoolTransformations}>
                  {t('services.poolCleaning.yearLong.greatPoolTransformations')}
                </Text>
              </View>

              {renderOrderPoolCleaningContainer()}

              <Text variant="bodySmall" style={style.footer}>
                {t('services.poolCleaning.yearLong.servicesWillBeCustomized')}
              </Text>
            </View>
          </StyledOrderPoolCleaningInfoContainer>
        </ScrollView>
      ) : (
        <View>
          <Text variant="headlineLarge">{t('services.poolCleaning.yearLong.title')}</Text>
        </View>
      )}
      <OrderPoolCleaningFooter />
    </SafeAreaView>
  );
}
