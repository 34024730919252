import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    orderPoolCleaningContainer: {
      backgroundColor: theme.colors.white.primary,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: theme.colors.gray.secondary,
      padding: theme.sizing.s6,
    },

    orderPoolCleaningSubtitle: {
      fontWeight: '700',
      marginTop: theme.sizing.s5,
    },

    orderPoolCleaningDescription: {
      marginTop: theme.sizing.s3,
    },

    orderPoolCleaningItems: {
      marginTop: theme.sizing.s3,
      flexDirection: 'row',
      alignItems: 'center',
    },

    imagesContainer: {
      marginTop: theme.sizing.s6,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    smallImagesContainer: {
      flex: 1,
      alignItems: 'center',
    },

    smallImagesContainerPadding: {
      flex: 1,
      alignItems: 'center',
      marginLeft: theme.sizing.s3,
    },

    smallImage: {
      height: 160,
      width: '100%',
    },

    largeImagesContainer: {
      marginTop: theme.sizing.s3,
      alignItems: 'center',
    },

    largeImage: {
      height: 400,
      width: '100%',
    },

    footer: {
      marginTop: theme.sizing.s2,
      fontWeight: '500',
      color: theme.colors.gray.tertiary,
      padding: 10,
    },
    goBackIcon: {
      width: theme.sizing.s6,
      height: theme.sizing.s6,
      borderColor: '#E5E7EB',
      backgroundColor: '#E5E7EB',
      borderRadius: 9999,
    },

    greatPoolTransformations: {
      fontWeight: '700',
      marginTop: theme.sizing.s6,
      marginBottom: -theme.sizing.s4,
    },
  });
};

export const StyledOrderPoolCleaningContainer = tw.section`
flex flex-col
items-start
md:flex-row-reverse md:gap-6
`;

export const OrderPoolCleaningContainer = tw.div`
w-full md:w-[454px] lg:w-[549px] xl:w-[613px] [@media(min-width:2000px)]:w-[900px]
`;

export const StyledOrderPoolCleaningInfoContainer = tw.div`
mt-5 w-full mx-auto
md:w-8/12
`;
