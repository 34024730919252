import { ReactNode } from 'react';
import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { ProgressHeader } from '@lawnstarter/ls-react-common/molecules';

import { BackButton } from '@src/components/atoms';

import {
  BackButtonWrapper,
  Container,
  FormDescription,
  FormTitle,
  FormWrapper,
  SelectOptionWrapper,
} from './styles';

export interface PoolCleaningQuestionProps {
  title: string;
  description: string;
  progress: number;
  onBackButtonPress?: () => void;
  children?: ReactNode;
}

export function PoolCleaningQuestion({
  title,
  description,
  progress,
  onBackButtonPress,
  children,
}: PoolCleaningQuestionProps) {
  return (
    <Container>
      <BackButtonWrapper>
        <BackButton {...(onBackButtonPress && { onPress: onBackButtonPress })} />
      </BackButtonWrapper>
      <ProgressHeader
        progress={progress}
        progressText={t('services.poolCleaning.orderService.title')}
      />
      <FormWrapper>
        <View style={{ flex: 1 }}>
          <FormTitle>{title}</FormTitle>
          <FormDescription>{description}</FormDescription>
        </View>
        <SelectOptionWrapper>{children}</SelectOptionWrapper>
      </FormWrapper>
    </Container>
  );
}
