import { StyleSheet } from 'react-native';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    footerContainer: {
      position: 'fixed' as any,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      width: '100%',
      marginTop: -20,
      backgroundColor: theme.colors.white.primary,
      shadowColor: 'black',
      shadowOffset: { width: 0, height: -theme.sizing.s1 },
      shadowOpacity: 0.1,
      shadowRadius: theme.sizing.s1,
    },
    footerText: {
      fontWeight: '700',
      marginTop: theme.spacing.s5,
      alignSelf: 'center',
      color: theme.colors.gray.tertiary,
    },
    footerButton: {
      margin: theme.spacing.s5,
    },
  });
