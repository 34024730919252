import { useCallback, useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import { POOL_ISSUE_OPTIONS } from '@lawnstarter/customer-modules/constants';
import { useOrderContext } from '@lawnstarter/customer-modules/contexts';
import { OrderPoolFlowKeys } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { SelectOption, SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';

import { PoolCleaningQuestion } from '@src/components';

import { styles } from './styles';

export function PoolVisibleIssuesScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const { updateOrder, goToPreviousStep, currentState } = useOrderContext();

  const onSelect = useCallback(
    (selected: SelectOptionItemKey[]) => {
      updateOrder(OrderPoolFlowKeys.PoolVisibleIssues, selected);
    },
    [updateOrder],
  );

  const onNextButtonPress = () => {
    // eslint-disable-next-line no-console
    console.log(currentState);
  };

  return (
    <PoolCleaningQuestion
      title={t('services.poolCleaning.orderService.visibleIssues.title')}
      description={t('services.poolCleaning.orderService.visibleIssues.description')}
      progress={3 / 5}
      onBackButtonPress={goToPreviousStep}
    >
      <SelectOption.Multi options={POOL_ISSUE_OPTIONS} onSelect={onSelect} />

      <TouchableOpacity style={style.skipButton} onPress={() => {}}>
        <Text>{t('services.poolCleaning.orderService.visibleIssues.skipForNow')}</Text>
      </TouchableOpacity>

      <Button
        mode="contained"
        trackID={'pool_issues_next'}
        style={style.nextButton}
        onPress={onNextButtonPress}
      >
        {t('next')}
      </Button>
    </PoolCleaningQuestion>
  );
}
